@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eagle+Lake&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scrollbar-color: #3aafa9 #2b7a78;
}

body {
    font-family: 'Mulish', sans-serif;
    overflow-x: hidden;
}

.eagle {
    font-family: 'Eagle Lake', sans-serif;
}

.fyt:hover {
    text-shadow: 0 0 40px whitesmoke;
    cursor: default;
}

.whitesmoke:hover {
    text-shadow: 0 0 20px whitesmoke;
}

.swiper {
    height: max-content;
}

.swiper-button-prev {
    left: -35px !important;
    color: unset !important;
}

.swiper-button-next {
    right: -35px !important;
    color: unset !important;
}

.swiper-pagination-bullets {
    bottom: -30px !important;
    color: unset !important;
}

.timelinedate {
    font-size: 1.5rem !important;
    color: #1f252f;
    margin-top: -.8rem;
}

@media (max-width: 640px) {
    .timelinedate {
        font-size: 1rem !important;
    }
}

input:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #2b7a78;
}

::-webkit-scrollbar-thumb {
    background: #3aafa9;
    border-radius: 10px;
}
